<template>
  <v-container fluid>
    <v-row justify="center" class="my-10">
      <v-col :cols="12"><h2 class="text-h4 text-lg-h3 text-center mb-10">Wellness & Nutrition</h2></v-col>
      <v-col :cols="12" :sm="10" :md="9" :lg="8">
        <v-row justify="center">
          <v-col v-for="card in cards" :key="card.title" :lg="4" :md="6" :sm="6" :cols="12">
            <v-card flat :to="card.link">
              <v-img :src="card.img" height="250px"></v-img>
              <v-card-title class="" v-text="card.title"></v-card-title>
              <v-card-subtitle v-text="card.subtitle"></v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "WellnessAndNutrition",
  data: () => ({
    cards: [
      {
        title: "Diets that actually work",
        img:
                "https://images.unsplash.com/photo-1480072723304-5021e468de85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Alkaline Diet: Truth vs Myths",
        img:
                "https://images.unsplash.com/photo-1480072723304-5021e468de85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "The Keto Diet 101",
        img:
                "https://images.unsplash.com/photo-1480072723304-5021e468de85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Why broccoli & cauliflower might be bad for you",
        img:
                "https://images.unsplash.com/photo-1480072723304-5021e468de85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "How to lose that last 10 pounds",
        img:
                "https://images.unsplash.com/photo-1480072723304-5021e468de85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Best foods for Toning Up",
        img:
                "https://images.unsplash.com/photo-1480072723304-5021e468de85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Best NO Equipment Workouts",
        img:
                "https://images.unsplash.com/photo-1480072723304-5021e468de85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Fat Burning Foods",
        img:
                "https://images.unsplash.com/photo-1480072723304-5021e468de85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      }
    ]
  })
};
</script>

<style scoped></style>
