<template>
  <v-container fluid>
    <v-row justify="center" class="my-10">
      <v-col :cols="12">
        <h2 class="text-h4 text-lg-h3 text-center mb-10">Culture</h2>
      </v-col>
      <v-col :cols="12" :sm="10" :md="9" :lg="8">
        <v-row justify="center">
          <v-col
            v-for="card in cards"
            :key="card.title"
            :lg="4"
            :md="6"
            :sm="6"
            :cols="12"
          >
            <v-card class="mx-3" flat :to="card.link">
              <v-img :src="card.img" height="250px"></v-img>
              <v-card-title class="" v-text="card.title"></v-card-title>
              <v-card-subtitle v-text="card.subtitle"></v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Culture",
  data: () => ({
    cards: [
      {
        title: "Body Chains",
        img:
          "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Favourite Life Motos",
        img:
          "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Summer Sandal Hand",
        img:
          "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Laptop Cases",
        img:
          "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Knit Bralettes",
        img:
          "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "My Makeup Bag",
        img:
          "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      }
    ]
  })
};
</script>

<style scoped></style>
