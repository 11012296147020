<template>
  <v-container fluid>
    <v-row justify="center" class="my-10">
      <v-col :cols="12"><h2 class="text-h4 text-lg-h3 text-center mb-10">Beauty & Skincare</h2></v-col>
      <v-col :cols="12" :sm="10" :md="9" :lg="8">
        <v-row justify="center">
          <v-col v-for="card in cards" :key="card.title" :lg="4" :md="6" :sm="6" :cols="12">
            <v-card flat :to="card.link">
              <v-img :src="card.img" height="250px"></v-img>
              <v-card-title class="" v-text="card.title"></v-card-title>
              <v-card-subtitle v-text="card.subtitle"></v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BeautyAndSkincare",
  data: () => ({
    cards: [
      {
        title: "Natural scrubs & masks",
        img:
                "https://images.unsplash.com/photo-1578747763484-51b21a33e4fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "How to naturally curl hair (no heat)",
        img:
                "https://images.unsplash.com/photo-1578747763484-51b21a33e4fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Natural skincare remedies ",
        img:
                "https://images.unsplash.com/photo-1578747763484-51b21a33e4fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Ase Naturals Honest Review",
        img:
                "https://images.unsplash.com/photo-1578747763484-51b21a33e4fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Natural Hair Do’s & Don'ts",
        img:
                "https://images.unsplash.com/photo-1578747763484-51b21a33e4fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Naturally Curly, Kinky or Coily Hair Tips & Hairstyles",
        img:
                "https://images.unsplash.com/photo-1578747763484-51b21a33e4fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "How to naturally lighten dark spots",
        img:
                "https://images.unsplash.com/photo-1578747763484-51b21a33e4fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Beauty Life Hacks & Tips",
        img:
                "https://images.unsplash.com/photo-1578747763484-51b21a33e4fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      }
    ]
  })
};
</script>

<style scoped></style>
