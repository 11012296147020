<template>
  <v-container fluid>
    <v-row justify="center" class="my-10">
      <v-col :cols="12"><h2 class="text-h4 text-lg-h3 text-center mb-10">Lifestyle</h2></v-col>
      <v-col :cols="12" :sm="10" :md="9" :lg="8">
        <v-row justify="center">
          <v-col v-for="card in cards" :key="card.title" :lg="4" :md="6" :sm="6" :cols="12">
            <v-card flat :to="card.link">
              <v-img :src="card.img" height="250px"></v-img>
              <v-card-title class="" v-text="card.title"></v-card-title>
              <v-card-subtitle v-text="card.subtitle"></v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Lifestyle",
  data: () => ({
    cards: [
      {
        title: "20 things to do before 2020 ends",
        img:
                "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "How to get rid of a bad habit",
        img:
                "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Covid-19 Date Night Ideas",
        img:
                "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Safest travel destinations winter 2020",
        img:
                "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Favourite Underground & Upcoming Artists",
        img:
                "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "How to become an IG influencer",
        img:
                "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Must Read Books for Fall",
        img:
                "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1393&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      }
    ]
  })
};
</script>

<style scoped></style>
