<template>
  <v-container fluid class="fill-height">
    <v-row justify="center" align-md="center" class="my-10">
      <!--      <v-col :cols="12">-->
      <!--        <h2 class="text-h4 text-lg-h3 text-center mb-10">About</h2>-->
      <!--      </v-col>-->
      <v-col :cols="12" :sm="5" :md="4">
        <v-img
          class="rounded-circle ml-auto mr-auto"
          height="200"
          max-width="315"
          src="../assets/images/About-Pic.jpeg"
        ></v-img>
      </v-col>
      <v-col :cols="12" :sm="5" :md="6">
        <p class="intro intro2">Hi friends!</p>
        <p class="intro intro2">
          Being the creative force behind this digital space. My hope is that
          you’ll stumble across inspiration, life hacks & tools to conquer each
          & every day mixed with insight into my heart & soul - enjoy! ✨
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="10">
        <v-divider class="my-3"></v-divider>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="12" :sm="10" :md="10">
        <h2 class="text-h4 text-lg-h3 text-center mb-10">About</h2>
        <p class="text-body-1 text-md-h6 font-weight-light mb-10">
          “Toronto based & originating from Montreal, Shinnel Brown, is a Fashion & Business Alumni with a passion for activism, music, literature & always being truly authentic to your own originality. In this digital space you’ll find everything from life hacks, nutrition meal plans, & trending fashion looks to hilarious content that’ll make you laugh until you forget we’re currently in a pandemic.
        </p>
        <p class="text-body-1 text-md-h6 font-weight-light">
          23 years old and born in Canada with a background of caribbean descent, Shinnel Brown graduated from highschool in 2014 and went on to study Fashion & Business management while also working full-time in the retail world. Following graduation she’s worked in the fashion management, marketing and eventually e-commerce industry. With life experiences beyond her years and a passion for songwriting & dance, Shinnel is currently in the midst of working on her debut EP rooted in the genres of R&B, Rap & of course her own personal touch - Stay tuned!</p>

<!--        <p class="test mb-10">-->
<!--          “Toronto based & originating from Montreal, Shinnel Brown, is a Fashion & Business Alumni with a passion for activism, music, literature & always being truly authentic to your own originality. In this digital space you’ll find everything from life hacks, nutrition meal plans, & trending fashion looks to hilarious content that’ll make you laugh until you forget we’re currently in a pandemic.-->
<!--        </p>-->
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="10">
        <v-divider class="my-3"></v-divider>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        v-for="instaPic in instaPics"
        :key="instaPic.img"
        :cols="instaPic.cols"
        :sm="instaPic.sm"
        :md="instaPic.md"
      >
        <v-img height="100%" :src="instaPic.img"></v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="10">
        <v-divider class="my-3"></v-divider>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-row>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",
  components: {},
  data: () => ({
    instaPics: [
      {
        img:
          "https://images.unsplash.com/photo-1592530392525-9d8469678dac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 12,
        sm: 6,
        md: 2
      },
      {
        img:
          "https://images.unsplash.com/photo-1604734517532-faaf676598fb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 6,
        sm: 6,
        md: 2
      },
      {
        img:
          "https://images.unsplash.com/photo-1591197415080-d849883ecb27?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 6,
        sm: 6,
        md: 2
      },
      {
        img:
          "https://images.unsplash.com/photo-1604315808880-5efe448fe8e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 6,
        sm: 6,
        md: 2
      },
      {
        img:
          "https://images.unsplash.com/photo-1603089741107-40ee02e7ec87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 6,
        sm: 6,
        md: 2
      }
    ]
  })
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");

@media only screen and (max-width: 599px) {
  .intro {
    font-family: "Caveat", cursive;
    color: black !important;
    text-decoration: none;
    font-size: 15px;
  }
}

@media only screen and (min-width: 600px) {
  .intro2 {
    font-family: "Caveat", cursive;
    color: black !important;
    text-decoration: none;
    font-size: 25px;
  }
}

@media only screen and (min-width: 600px) {
  .test {
    font-family: "Oswald", sans-serif;
    color: black !important;
    text-decoration: none;
    font-size: 20px;
  }
}
</style>
