<template>
  <v-container fluid class="fill-height">
    <v-row justify="center">
      <v-col :cols="12">
        <h2 class="text-h4 text-center">Contact</h2>
      </v-col>
      <v-col :cols="12">
        <h3 class="text-h5 text-center">
          For press/media and business collaborations:
        </h3>
      </v-col>
      <v-col :cols="12" class="text-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a href="mailto: shinnel_brown@hotmail.com">
              <v-btn class="text-h4" large text v-bind="attrs" v-on="on" bottom>
                shinnel_brown@hotmail.com
              </v-btn>
            </a>
          </template>
          <span>Email Shinnel Brown</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Contact"
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
