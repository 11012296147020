<template>
  <nav>
    <v-card>
      <v-app-bar absolute prominent app flat color="white">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <router-link to="/" class="logo logo2">
            <div>
              <v-row justify="center">
                <v-col :cols="10">
                  <v-divider class="my-3"></v-divider>
                </v-col>
              </v-row>
              <span class="logo logo2">Shinnel </span>
              <span class="logo logo2">Brown</span>
              <v-row justify="center">
                <v-col :cols="10">
                  <v-divider class="my-3"></v-divider>
                </v-col>
              </v-row>
            </div>
          </router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn text icon class=""><v-icon>mdi-instagram</v-icon></v-btn>
        <v-btn text icon class="d-none d-sm-flex"><v-icon>mdi-youtube</v-icon></v-btn>

        <template v-slot:extension>
          <v-row justify="center" class="pb-0 pb-sm-4">
            <div class="d-none d-md-flex d-lg-flex d-xl-flex">
              <v-btn text to="/">Home</v-btn>
              <v-btn text to="/about">About</v-btn>
              <v-btn text to="/culture">Culture</v-btn>
              <v-btn text to="/fashion">Fashion</v-btn>
              <v-btn text to="/lifestyle">Lifestyle</v-btn>
              <v-btn text to="/beauty">Beauty & Skincare</v-btn>
              <v-btn text to="/wellness">Wellness & Nutrition</v-btn>
              <v-btn text to="/contact">Contact</v-btn>
            </div>
          </v-row>
        </template>
      </v-app-bar>
    </v-card>

    <v-navigation-drawer width="400" v-model="drawer" absolute temporary app>
      <v-container>
        <v-row justify="center">
          <v-col :cols="10">
            <div class="text-center text-h5 mb-3">Shinnel Brown</div>
            <v-img src="../assets/images/About-Pic.jpeg"></v-img>
          </v-col>
          <v-col :cols="10"
            ><div class="text-body-1 text-center">
              Entrepreneur, content creator and founder of Shinnel Studios. I
              sell clothing, make videos and I put them on the internet for
              people to enjoy.
            </div></v-col
          >
          <v-col :cols="12" class="text-center pt-0">
            <v-btn text icon><v-icon>mdi-facebook</v-icon></v-btn>
            <v-btn text icon><v-icon>mdi-twitter</v-icon></v-btn>
            <v-btn text icon><v-icon>mdi-instagram</v-icon></v-btn>
            <v-btn text icon><v-icon>mdi-youtube</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="text-center">
          <v-col :cols="12"><v-btn text to="/">Home</v-btn></v-col>
          <v-col :cols="12"><v-btn text to="/about">About</v-btn></v-col>
          <v-col :cols="12"><v-btn text to="/culture">Culture</v-btn></v-col>
          <v-col :cols="12"><v-btn text to="/fashion">Fashion</v-btn></v-col>
          <v-col :cols="12"
            ><v-btn text to="/lifestyle">Lifestyle</v-btn></v-col
          >
          <v-col :cols="12"
            ><v-btn text to="/beauty">Beauty & Skincare</v-btn></v-col
          >
          <v-col :cols="12"
            ><v-btn text to="/wellness">Wellness & Nutrition</v-btn></v-col
          >
          <v-col :cols="12"><v-btn text to="/contact">Contact</v-btn></v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  data: () => ({ drawer: false })
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nosifer&display=swap");

@media only screen and (max-width: 599px) {
  .logo {
    font-family: "Nosifer", cursive;
    color: hotpink !important;
    text-decoration: none;
    font-size: 18px;
  }
}

@media only screen and (min-width: 600px) {
  .logo2 {
    font-family: "Nosifer", cursive;
    color: hotpink !important;
    text-decoration: none;
    font-size: 38px;
  }
}
</style>
