<template>
  <v-container>
    <v-row justify="center">
      <v-col :cols="12" :sm="12" :md="10">
        <VueSlickCarousel v-bind="settings">
            <v-col v-for="blog in blogs" :key="blog.title">
              <v-card flat :to="blog.link">
                <v-img :src="blog.img" height="400"></v-img>
              <v-card-title
                class="justify-center text-h5 text-sm-h4 font-weight-medium"
                >{{ blog.title }}</v-card-title>
                <v-card-text class="text-center text-subtitle-1">
                  {{ blog.subTitle }}
                </v-card-text>
              </v-card>
            </v-col>
        </VueSlickCarousel>
      </v-col>
    </v-row>
    <!--    row one with spotify-->
    <v-col>
    <v-row class="mt-16" justify="center">
      <v-col :cols="12" :sm="8" :md="7">
        <h3 class="text-center text-h6 pb-0">Paris Trip | Lifestyle</h3>
        <h4 class="text-center text-body-1 pb-0">The City of Love</h4>
      </v-col>
      <v-col class="d-none d-sm-flex" :cols="12" :sm="4" :md="3">
        <v-col :cols="12" class="pa-0">
          <h3 class="d-none d-sm-flex justify-center text-h6 pb-0">
            Current Vibes | 2020
          </h3>
          <h4 class="d-none d-sm-flex justify-center text-body-1 pb-0">
            Music you may Love
          </h4>
        </v-col>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="12" :sm="8" :md="7">
        <v-card height="100%" flat>
          <v-img
            height="100%"
            src="https://images.unsplash.com/photo-1500313830540-7b6650a74fd0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
          ></v-img>
        </v-card>
      </v-col>
      <v-col class="d-flex d-sm-none" :cols="12" >
        <v-col :cols="12" class="pa-0">
          <h3 class="text-center text-h6 pb-0">Current Vibes | 2020</h3>
          <h4 class="text-center text-body-1 pb-0">Music you may Love</h4>
        </v-col>
      </v-col>
      <v-col :cols="12" :sm="4" :md="3">
        <v-card flat height="100%">
          <iframe
            src="https://open.spotify.com/embed/playlist/39T0FnIu12GaW03qtpQo8x"
            width="100%"
            height="100%"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="12" :sm="12" :md="10">
        <v-divider class="my-3"></v-divider>
      </v-col>
    </v-row>
    <!--    row two with spotify-->
    <v-row class="" justify="center">
      <v-col :cols="12" :sm="8" :md="7">
        <h3 class="text-center text-h6 pb-0">Unity | Beauty & Skincare</h3>
        <h4 class="text-center text-body-1 pb-0">The United Look for Women</h4>
      </v-col>
      <v-col class="d-none d-sm-flex" :cols="12" :sm="4" :md="3">
        <v-col :cols="12" class="pa-0">
          <h3 class="text-center text-h6 pb-0">Old Skool | Timeless</h3>
          <h4 class="text-center text-body-1 pb-0">Music you may Love</h4>
        </v-col>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="12" :sm="8" :md="7">
        <v-card height="100%" flat>
          <v-img
            height="100%"
            src="https://images.unsplash.com/photo-1493655161922-ef98929de9d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80">
          </v-img>
        </v-card>
      </v-col>
      <v-col class="d-flex d-sm-none" :cols="12">
        <v-col :cols="12" class="pa-0">
          <h3 class="text-center text-h6 pb-0">Old Skool | Timeless</h3>
          <h4 class="text-center text-body-1 pb-0">Music you may Love</h4>
        </v-col>
      </v-col>
      <v-col :cols="12" :sm="4" :md="3">
        <v-card flat height="100%">
          <iframe
            src="https://open.spotify.com/embed/playlist/4SIGBHZuGvO8nIY59JnAhL"
            width="100%"
            height="100%"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="12" :sm="12" :md="10">
        <v-divider class="my-3"></v-divider>
      </v-col>
    </v-row>
    </v-col>
    <!--    Keep up with me-->
    <v-col>
    <v-row justify="center"
      ><span class="text-uppercase subscribe">keep up with me</span></v-row
    >
    <v-row justify="center" align="center">
      <v-col :cols="5" :sm="5" :md="2" class="pr-0">
        <span class="text-h5 subscribe">For all the latest....</span>
      </v-col>
      <v-col :cols="7" :sm="5" :md="3">
        <v-form>
          <v-text-field
            color="pink darken-4"
            label="Email"
            dense
            outlined
          ></v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-8">
      <v-col v-for="instaPic in instaPics" :key="instaPic.img" :cols="instaPic.cols" :sm="instaPic.sm" :md="instaPic.md">
        <v-img
          height="100%"
          :src="instaPic.img"
        ></v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="12" :md="10">
        <v-divider class="my-3"></v-divider>
      </v-col>
    </v-row>
    </v-col>
  </v-container>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Home",
  components: { VueSlickCarousel },
  data: () => ({
    settings: {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      touchThreshold: 5,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    },
    blogs: [
      {
        title: "Skin Care 2021",
        img:
          "https://images.unsplash.com/photo-1578747763484-51b21a33e4fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        subTitle: "Jan 1st, 2020",
        link: "/about"
      },
      {
        title: "Sushi Spot",
        img:
          "https://images.unsplash.com/photo-1540648639573-8c848de23f0a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1424&q=80",
        subTitle: "Feb 1st, 2020",
        link: "/about"
      },
      {
        title: "Fall Fashion",
        img:
          "https://images.unsplash.com/photo-1479492591199-eb2492814e73?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        subTitle: "March 1st, 2020",
        link: "/about"
      },
      {
        title: "Starting Youtube",
        img:
          "https://images.unsplash.com/photo-1548328928-34db1c5fcc1f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        subTitle: "April 1st, 2020",
        link: "/about"
      },
      {
        title: "Halloween Plans",
        img:
          "https://images.unsplash.com/photo-1508361001413-7a9dca21d08a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        subTitle: "Oct 31st, 2020",
        link: "/about"
      },
      {
        title: "Secret Recipe",
        img:
          "https://images.unsplash.com/photo-1532499016263-f2c3e89de9cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80",
        subTitle: "June 16st, 2020",
        link: "/about"
      }
    ],
    instaPics: [
      {
        img:
                "https://images.unsplash.com/photo-1592530392525-9d8469678dac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 12,
        sm: 6,
        md: 2
      },
      {
        img:
                "https://images.unsplash.com/photo-1604734517532-faaf676598fb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 6,
        sm: 6,
        md: 2
      },
      {
        img:
                "https://images.unsplash.com/photo-1591197415080-d849883ecb27?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 6,
        sm: 6,
        md: 2
      },
      {
        img:
                "https://images.unsplash.com/photo-1604315808880-5efe448fe8e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 6,
        sm: 6,
        md: 2
      },
      {
        img:
                "https://images.unsplash.com/photo-1603089741107-40ee02e7ec87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        cols: 6,
        sm: 6,
        md: 2
      }
    ]
  })
};
</script>

<style>
.subscribe {
  font-family: "Nosifer", cursive;
  color: #880e4f !important;
  font-size: 25px;
}
</style>
