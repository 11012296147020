<template>
  <v-app>
    <navigation />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    Footer,
    Navigation
  },

  data: () => ({
    //
  })
};
</script>
