<template>
  <v-footer padless class="footer-color">
    <v-container>
      <v-col>
      <v-row justify="center" class="py-6">
        <v-col
          order="2"
          order-sm="2"
          order-md="2"
          :cols="12"
          :sm="6"
          :md="3"
          class="text-subtitle-1 text-center text-sm-start"
        >
          <div class="text-uppercase">@shinnelbrown / @shinneloffical</div>
          <v-btn text to="/contact">Contact</v-btn>
        </v-col>
        <v-col class="text-center" order="1" order-sm="1" order-md="2" :cols="12" :sm="12" :md="4">
          <span class="logo logo2">Shinnel </span>
          <span class="logo logo2">Brown</span>
          <v-row justify="center">
            <v-col :cols="12">
              <v-divider class=""></v-divider>
            </v-col>
          </v-row>
          <v-icon size="24px">mdi-copyright</v-icon>
          {{ new Date().getFullYear() }} Copyright - Shinnel Brown
        </v-col>
        <v-col class="text-center text-sm-end" order="3" order-sm="3" order-md="3" :cols="12" :sm="6" :md="3">
          <span>Podcast / Youtube</span>
          <div>
            <v-btn text icon><v-icon>mdi-instagram</v-icon></v-btn>
            <v-btn text icon><v-icon>mdi-youtube</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>
      </v-col>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nosifer&display=swap");

@media only screen and (max-width: 599px) {
  .logo {
    font-family: "Nosifer", cursive;
    color: hotpink !important;
    text-decoration: none;
    font-size: 18px;
  }
}

@media only screen and (min-width: 600px) {
  .logo2 {
    font-family: "Nosifer", cursive;
    color: hotpink !important;
    text-decoration: none;
    font-size: 30px;
  }
}

.footer-color {
  background-color: #ECEFF1;
}
</style>
