import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About";
import Fashion from "../views/Fashion";
import Lifestyle from "../views/Lifestyle";
import BeautyAndSkincare from "../views/BeautyAndSkincare";
import WellnessAndNutrition from "../views/WellnessAndNutrition";
import Contact from "../views/Contact";
import Culture from "../views/Culture";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/culture",
    name: "Culture",
    component: Culture
  },
  {
    path: "/fashion",
    name: "Fashion",
    component: Fashion
  },
  {
    path: "/lifestyle",
    name: "Lifestyle",
    component: Lifestyle
  },
  {
    path: "/beauty",
    name: "Beauty",
    component: BeautyAndSkincare
  },
  {
    path: "/wellness",
    name: "Wellness",
    component: WellnessAndNutrition
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
