<template>
  <v-container fluid>
    <v-row justify="center" class="my-10">
      <v-col :cols="12"><h2 class="text-h4 text-lg-h3 text-center mb-10">Fashion</h2></v-col>
      <v-col :cols="12" :sm="10" :md="9" :lg="8">
        <v-row justify="center">
          <v-col v-for="card in cards" :key="card.title" :lg="4" :md="6" :sm="6" :cols="12">
            <v-card flat :to="card.link">
              <v-img :src="card.img" height="250px"></v-img>
              <v-card-title class="" v-text="card.title"></v-card-title>
              <v-card-subtitle v-text="card.subtitle"></v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Fashion",
  data: () => ({
    cards: [
      {
        title: "10 trends worth trying",
        img:
          "https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Beyonce’s Top Looks",
        img:
          "https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Top Kardashian/Jenner Looks",
        img:
          "https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Style Guide Fall/Winter 2020",
        img:
          "https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Trending Looks: October",
        img:
          "https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      },
      {
        title: "Best Fashion Halloween Slays",
        img:
          "https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        subtitle: "Test Subtitle",
        link: "/"
      }
    ]
  })
};
</script>

<style scoped></style>
